<template>
  <div class="vlist">
    <Navigation />
  </div>
</template>

<script>
import Navigation from "./Navigation.vue";

export default {
  name: "SideNav",
  components: {
    Navigation,
  },
};
</script>

<style>
</style>