import { createRouter, createWebHashHistory } from 'vue-router'
import Blog from '../components/Blog.vue'
import Links from '../components/Links.vue'
// import IFrame from '../components/IFrame.vue'
import Wufoo from '../components/Wufoo.vue'

const routes = [
  { path: "/", name: "Home", component: Blog, props: { featured: true } },
  { path: "/highlights", name: "Highlights", component: Blog, props: { category: "Highlights" } },
  { path: "/ju-jitsu", name: "Ju Jitsu", component: Blog, props: { category: "Ju Jitsu" } },
  { path: "/judo", name: "Judo", component: Blog, props: { category: "Judo" } },
  { path: "/martial-arts", name: "Martial Arts", component: Blog, props: { category: "Related Martial Arts" } },
  { path: "/links", name: "Links", component: Links },
  { path: "/contact", name: "Contact", component: Wufoo },
  { path: "/disclaimer", name: "Disclaimer", component: Blog, props: { category: "Disclaimer" } }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

// https://stackoverflow.com/questions/46083220/how-to-vuejs-router-link-active-style
const router = createRouter({
  history: createWebHashHistory(),
  routes,
  //linkExactActiveClass: "current_link active_link"
})

export default router
