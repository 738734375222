<template>
  <div class="leading">
    <h2>{{ article.title }}</h2>
    <dl class="article-info">
      <dt class="article-info-term">Details</dt>
      <dd class="createdby">Written by EJJA</dd>
    </dl>
    <div v-html="article.content"></div>
    <div class="item-separator"></div>
  </div>
</template>

<script>
export default {
  name: "Article",
  props: ['article']
};
</script>

<style>
</style>