<template>
  <div id="app" class="page">
    <Header />
    <MainNav />
    <!-- begin: main content area #main -->
    <div id="main">
      <Sidebar />
      <!-- begin: #col3 static column -->
      <div id="col3" role="main">
        <div id="col3_content" class="clearfix">
          <div id="col3_content_wrapper">
            <a id="content" name="content" class="skiplink-anchor"></a>
            <router-view></router-view>
          </div>
        </div>
        <div id="ie_clearing">&nbsp;</div>
        <!-- End: IE Column Clearing -->
      </div>
      <!-- end: #col3 -->
    </div>
    <!-- end: #main -->
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import MainNav from "./components/MainNav.vue";
import Sidebar from "./components/Sidebar.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    MainNav,
    Sidebar,
    Footer,
  }
};
</script>
