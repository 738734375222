<template>
  <div id="header" role="banner">
    <h1>
      <a href="index.html">
        <img
          src="@/assets/images/logo_white.png"
          width="190"
          height="219"
          alt="Judo & Ju-Jitsu Associations and Clubs in Ethiopia"
          class="site-logo"
        />
      </a>
      <div class="custom">
        <div id="title" class="zemen">በኢትዮጵያ ጁዶ እና ጁጂትሱ ማህበራትና ክለቦች</div>
      </div>
    </h1>
    <div class="custom">
      <div class="tooltip">
        <h2 style="font-weight: bold">
          EJJA - Judo &amp; Ju-Jitsu Associations and Clubs in Ethiopia<!--sup style="font-size: x-small; color: black;"> 1</sup><span class="tooltiptext">Platform for Judo and Ju-Jutsu activities, clubs and associations in Ethiopia.</span-->
        </h2>
      </div>
    </div>
    <div class="custom">
      <div id="hair">
        <img src="@/assets/images/hair_small_white.png" border="0" />
      </div>
    </div>
    <div class="bannergroup">
      <div class="banneritem">
        <div class="slogans">
          gentle art<br /><span class="zemen">ጨዋው ጥበብ</span>
        </div>
        <div class="clr"></div>
      </div>
    </div>
    <div class="custom">
      <div id="fontinfo" class="note">
        To see Amharic characters, you will need a font that supports Ethiopic,
        such as
        <a href="downloads/other/fonts/gfzemenu.ttf">GF Zemen Unicode</a>.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>