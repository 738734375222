<template>
  <div>
    <h2>
      <span class="subheading-category">Links</span>
    </h2>
    <ul>
      <li v-for="link in links" :key="link.title">
        <a :href="link.href">{{ link.title }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import data from "../data/links.json";

export default {
  name: "Links",
  data() {
    return {
      links: data.links,
    };
  },
};
</script>

<style>
</style>