<template>
  <div id="col1" role="complementary">
    <div id="col1_content" class="clearfix">
      <div class="moduletable">
        <SideNav />
      </div>
      <div class="moduletable">
        <div class="custom">
          <fb:like
            href="http://www.facebook.com/pages/Ethiopian-Judo-Ju-Jitsu-Association/181481398591493"
            layout="button_count"
            action="like"
            show_faces="false"
            share="false"
          ></fb:like>
        </div>
      </div>
      <div class="moduletable">
        <div class="custom">
          <p>
            <iframe
              title="YouTube video player"
              src="https://www.youtube.com/embed/zEQg6DQShE0?wmode=transparent&amp;showinfo=0"
              frameborder="0"
              width="208"
              height="186"
            ></iframe>
          </p>
        </div>
      </div>
      <div class="moduletable">
        <div class="custom">
          <p>
            <iframe
              title="YouTube video player"
              src="https://www.youtube.com/embed/Gg7wP4Avyo0?wmode=transparent&amp;showinfo=0"
              frameborder="0"
              width="208"
              height="186"
            ></iframe>
          </p>
        </div>
      </div>
      <div class="moduletable">
        <div class="custom">
          <div align="center">
            <p>
              <a href="http://www.jjif.org" target="_blank" title="JJIF"
                ><img
                  src="@/assets/images/logo-jjif1.jpg"
                  border="0"
                  alt="JJIF"
                  title="JJIF"
                  width="146"
                  height="96"
              /></a>
            </p>
            <p>
              <a href="https://www.ijf.org" target="_blank" title="IJF"
                ><img
                  src="@/assets/images/ijflogo.gif"
                  border="0"
                  alt="IJF"
                  title="IJF"
                  width="150"
              /></a>
            </p>
            <p>
              <a href="http://www.jjafu.org" target="_blank" title="JJAFU"
                ><img
                  src="@/assets/images/logo_jjafu.jpg"
                  border="0"
                  alt="JJAFU"
                  title="JJAFU"
                  width="150"
              /></a>
            </p>
            <p>
              <a href="https://www.africajudo.org" target="_blank" title="UAJ"
                ><img
                  src="@/assets/images/AJU.png"
                  border="0"
                  alt="UAJ"
                  title="AJU"
                  width="150"
              /></a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from "./SideNav.vue";

export default {
  name: "Sidebar",
  components: {
    SideNav,
  },
};
</script>

<style>
</style>