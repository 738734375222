<template>
  <div id="footer" role="contentinfo">
    <div id="footer_content" class="clearfix">
      © 2021 Judo & Ju-Jitsu Associations and Clubs in Ethiopia
      <span
        class="float_right a-right"
        style="
          border-left: 1px solid #ccc;
          padding-left: 1em;
          margin-top: 0.5em;
        "
      >
        <!-- **********************************************************************
              (de) Folgende Rückverlinkungen dürfen nur entfernt werden, wenn
                   Sie eine JYAML und/oder eine YAML Lizenz besitzen.
              (en) Following backlinks may be only removed, if
                   you are owner of a JYAML and/or a YAML license.
                :: http://www.jyaml.de
                :: http://www.yaml.de
            *********************************************************************** -->
        Layout based on
        <a href="http://www.jyaml.de/" target="_blank">JYAML</a> and
        <a href="http://www.yaml.de/" target="_blank">YAML</a>
        <!-- ****************************************************************** -->
        <br /><small
          >Responsive design &<br />SPA based on Vue.js
          <br />by
          <a href="http://www.ignobilis.de/" target="_blank"
            >Sebastian Schmidt</a
          ></small
        >
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>