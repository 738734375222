<template>
  <ul class="menu">
    <!-- https://router.vuejs.org/api/#v-slot-api-3-1-0
    https://stackoverflow.com/questions/41168307/vue-js-2-0-router-link-in-a-div-component#63956686
    https://next.router.vuejs.org/guide/migration/#removal-of-the-exact-prop-in-router-link -->
    <router-link
      v-for="link in links"
      :key="link.name"
      :to="link.path"
      v-slot="{ href, navigate, isExactActive }"
      custom
    >
      <li
        class="item-76 item-first item-count0 level1 viewLevel0 type-component"
        :class="[isExactActive && 'current active']"
      >
        <a
          :href="href"
          @click="navigate"
          :class="[isExactActive && 'current_link active_link']"
        >
          <span class="item-title">{{ link.name }}</span>
        </a>
      </li>
    </router-link>
    <!-- <router-link to="/" v-slot="{ href, navigate, isExactActive }" custom>
      <li
        class="item-76 item-first item-count0 level1 viewLevel0 type-component"
        :class="[isExactActive && 'current active']"
      >
        <a
          :href="href"
          @click="navigate"
          :class="[isExactActive && 'current_link active_link']"
        >
          <span class="item-title">Home</span>
        </a>
      </li>
    </router-link>
    <router-link to="/highlights" v-slot="{ href, navigate, isExactActive }" custom
      >
      <li
        class="item-9 item-count1 level1 viewLevel0 type-component"
        :class="[isExactActive && 'current active']"
      >
        <a
          :href="href"
          @click="navigate"
          :class="[isExactActive && 'current_link active_link']"
        >
          <span class="item-title">Highlights</span>
        </a>
      </li>
    </router-link> -->
    <!-- <li class="item-5 item-count4 level1 viewLevel0 type-component">
      <a href="index.php/martial-arts.html"
        ><span class="item-title">Martial Arts</span></a
      >
    </li> -->
  </ul>
</template>

// https://stackoverflow.com/questions/36120996/get-all-routes-in-a-vue-router
<script>
export default {
  name: "Navigation",
  created() {
    this.$router.options.routes.forEach((route) => {
      this.links.push({
        name: route.name,
        path: route.path,
      });
    });
  },
  data() {
    return {
      // links: [
      //   { path: "/", name: "Home" },
      //   { path: "/highlights", name: "Highlights" },
      //   { path: "/ju-jitsu", name: "Ju Jitsu" }
      // ]
      links: [],
    };
  },
};
</script>

<style>
</style>