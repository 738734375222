https://stackoverflow.com/questions/54411394/how-to-configure-eslint-to-ignore-particular-errors-in-vue-cli-3-project hat nicht funktioniert

<template>
  <div class="contentpane">
    <div id="wufoo-zgjyizv1mphx7b">
      Fill out my
      <a href="https://ejja.wufoo.com/forms/zgjyizv1mphx7b">online form</a>.
    </div>
  </div>
</template>

<script>
// https://stackoverflow.com/questions/45047126/how-to-add-external-js-scripts-to-vuejs-components
// https://gist.github.com/james2doyle/28a59f8692cec6f334773007b31a1523
// https://www.npmjs.com/package/vue-plugin-load-script
export default {
  name: "Wufoo",
  mounted() {
    var zgjyizv1mphx7b;
    let x = new Promise(function (resolve, reject) {
      let d = document,
        t = "script";
      var s = d.createElement(t);
      var options = {
        userName: "ejja",
        formHash: "zgjyizv1mphx7b",
        autoResize: true,
        height: "642",
        async: true,
        host: "wufoo.com",
        header: "show",
        ssl: true,
      };
      s.src =
        ("https:" == d.location.protocol ? "https://" : "http://") +
        "secure.wufoo.com/scripts/embed/form.js";
      s.onload = s.onreadystatechange = function () {
        var rs = this.readyState;
        if (rs) if (rs != "complete") if (rs != "loaded") return;
        resolve(options);
      };
      s.onerror = reject;
      var scr = d.getElementsByTagName(t)[0],
        par = scr.parentNode;
      par.insertBefore(s, scr);
    });
    x.then((options) => {
      try {
        zgjyizv1mphx7b = new WufooForm(); // ignore undef siehe https://eslint.org/docs/user-guide/configuring/ignoring-code#using-eslintignore-in-packagejson
        zgjyizv1mphx7b.initialize(options);
        zgjyizv1mphx7b.display();
      } catch (e) {
        console.log(e.message);
      }
    });
  },
};
</script>

<style>
</style>