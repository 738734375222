<template>
  <div :class="[featured ? 'blog-featured' : 'blog']" :key="category">
    <h2>
      <span class="subheading-category">{{ category }}</span>
    </h2>
    <div class="items-leading">
      <Article
        v-for="article in articles"
        :key="article.id"
        :article="article"
      />
    </div>
  </div>
</template>

<script>
import Article from "./Article.vue";
import data from "../data/articles.json";

export default {
  name: "Blog",
  components: {
    Article,
  },
  props: { category: String, featured: Boolean },
  data() {
    return {
      articles: this.getArticles(), // Wird nicht aktualisiert -> watch. Alternative: computed. Da die Daten aber später von einer API kommen sollen, ist watch besser:
      // "is most useful when you want to perform asynchronous or expensive operations in response to changing data" https://v3.vuejs.org/guide/computed.html#watchers
    };
  },
  // computed: {
  //   articles() {
  //     return this.getArticles();
  //   }
  // },
  watch: {
    category(newCategory, oldCategory) {
      if (newCategory != oldCategory) {
        this.articles = this.getArticles();
      }
    },
  },
  methods: {
    getArticles() {
      if (this.category) {
        return data.articles.filter((x) => x.category == this.category);
      } else {
        return data.articles.filter((x) => typeof x.home !== "undefined");
      }
    },
  },
};
</script>

<style>
</style>