<template>
  <div id="nav" class="clearfix" role="navigation">
    <a id="navigation" name="navigation" class="skiplink-anchor"></a>
    <div class="hlist">
      <Navigation />
    </div>
  </div>
</template>

<script>
import Navigation from "./Navigation.vue";
export default {
  name: "MainNav",
  components: {
    Navigation
  }
};
</script>

<style>
</style>